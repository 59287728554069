import React, { Component, useEffect } from "react";
import Home from "./Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LineBar from "../components/LineBar";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import Login from "../Admin/Login";
import AddBlogs from "../Admin/AddBlogs";
import Blog from "./Blog";
import Blogs from "../components/Blogs";

import Contact from "../components/Contact";
import Promos from "./Promos";
import Numero from "./Numero";
import Tethqar from "./Tethqar";
import SocialMedia from "./SocialMedia";
import PublicRelation from "./PublicRelation";
import ScrollToTop from "../components/ScrollToTop";
import "../Styles/styles.css";
import "../Styles/fonts.css";
import Aos from "aos";
import "./ln.css";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga";
ReactGA.initialize("G-HHCZZWVNWL");
ReactGA.pageview(window.location.pathname + window.location.search);

const Analytics = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return <EntryPont />;
};
function Promosprivacypolicy() {
  return <iframe src="/promos_privacypolicy.html" width="100%" height="600"  title="Promosprivacypolicy"></iframe>;
}

export class EntryPont extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ln: "en",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Router>
        <ScrollToTop />
        <div>
          <div
            className="container-fluid"
            style={{
              borderStyle: "solid",
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderBottomWidth: 3,
              borderColor: "#056292",
            }}
          >
            <Row>
              <Col xs={9} lg={11} md={11}></Col>
              <Col>
                <div className="ln">
                  {this.state.ln == "en" ? (
                    <button
                      className="btn"
                      onClick={() => this.setState({ ln: "ar" })}
                    >
                      عربي
                    </button>
                  ) : (
                    <button
                      className="btn"
                      onClick={() => this.setState({ ln: "en" })}
                    >
                      English
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          </div>



          <Routes>
            <Route exact path="/Admin_login" element={<Login />} />
            <Route exact path="/Add_blogs" element={<AddBlogs />} />
            <Route exact path="/Blogs" element={<Blogs />} />
            <Route exact path="/Blog/:id" element={<Blog />} />
            <Route path="/promos_privacypolicy" element={<Promosprivacypolicy />} />

            <Route
              exact
              path="/Promos"
              element={<Promos ln={this.state.ln} />}
            />
            <Route
              exact
              path="/Numero"
              element={<Numero ln={this.state.ln} />}
            />
            <Route
              exact
              path="/Tethqar"
              element={<Tethqar ln={this.state.ln} />}
            />
            <Route
              exact
              path="/Public_Relation_Profile"
              element={<PublicRelation ln={this.state.ln} />}
            />
            <Route
              exact
              path="/Social_Media_Marketing"
              element={<SocialMedia ln={this.state.ln} />}
            />
            <Route exact path="/" element={<Home ln={this.state.ln} />} />
            <Route exact path="/Home" element={<Home ln={this.state.ln} />} />
          </Routes>


        </div>
      </Router>
    );
  }
}

export default Analytics;
